<template>
  <div>
    <div class="image-container">
      <img src="../assets/cover-mobile.jpg" alt="image">
      <div class="overlay-text">OPEN INVITATION</div>
    </div>
    <div class="image-container">
      <img src="../assets/mobile-1.jpg" alt="image">
    </div>
    <div class="image-container">
      <img src="../assets/mobile-2.jpg" alt="image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
